import React, { useEffect } from 'react';
import Navbar from './Navbar/Navbar';
import Sidebar from './Sidebar/Sidebar';
import Footer from './Footer/Footer';
// import './Page1.css'; // Assuming you have separate CSS for Page1

const Page1 = () => {
  useEffect(() => {
    document.title = 'Legal Removal Request | Fасebооk Help Center';
  }, []);

  const handleButtonClick = () => {
    window.location.href = '/eFy2Rp4Th9Km2Lq1Ns8Xw3Zg6Vo5Sj0Fb3Te4Yr7Uq1Ix8Pm2Kn9Xs6Jz3Ob5Nh8Jr5Ft4'; // Redirect to the /about9 page
  };

  return (
    <div className="App">
      <Navbar />
      <div className="main-container">
        <Sidebar />
        <div className="content-container">
          <div className="content">
            <span style={{ color: '#1877F2', fontSize: '0.86rem' }}>Policies and Reporting</span>
            <h1 style={{ color: '#000', fontSize: '1.66rem', fontWeight:'bold', padding: '10px 0', fontFamily:'inherit' }}>Legal removal request</h1>
            <hr style={{ padding: '10px 0' }} />
            <p>
              If you believe that content on Fасebооk violates your personal legal rights or local law, it is possible that the content may also violate our
              <span style={{ color: '#1877F2' }}> Community Standards </span>
              (e.g. bullying, harassment, hate speech).
              <br /><br />
              You can report content that you think violates Fасebооk's Community Standards by using the
              <span style={{ fontWeight: '500' }}> Report </span>
              link that appears in the drop-down options near the content itself. You can find more information in the
              <span style={{ color: '#1877F2' }}> Help Centre.</span>
              <br /><br />
              If you would prefer to submit a request to remove content that you believe is unlawful, you can do so through our
              <span style={{ color: '#1877F2' }}> Legal removal request form</span>. Please bear in mind that reporting something to Fасebооk doesn't guarantee that it will be removed.
            </p>
            <br/>
            <button className="legalBtn" onClick={handleButtonClick}>Submit legal removal request</button>
            <div className="faq-section">
              <div className="faq-toggle" style={{ color: '#3a3a3a', fontSize: '1.0rem', fontWeight:'bold', padding: '10px 0' }}>
                What types of things aren't allowed on Fасebооk?
                <i className="fas fa-caret-down"></i>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Page1;
