import React from 'react';
import '../Navbar/Navbar.css';
import { FaShield } from "react-icons/fa6";
import { CiMemoPad } from "react-icons/ci";
import { FaInfo } from "react-icons/fa";
import { PiShieldCheckFill } from "react-icons/pi";

const Sidebar = () => { 
  return React.createElement(
    'aside',
    { className: 'sidebar' },
    React.createElement(
      'ul',
      null,
      React.createElement(
        'li',
        { className: 'sidebar-item' },
        React.createElement('br'),
        React.createElement('br'),
        React.createElement(
          'div',
          { className: 'icon-circle' },
          React.createElement('i', { className: 'far fa-compass' })
        ),
        'Using Facebook',
        React.createElement('span', { className: 'arrow' })
      ),
      React.createElement(
        'li',
        { className: 'sidebar-item' },
        React.createElement(
          'div',
          { className: 'icon-circle' },
          React.createElement('i', { className: 'fas fa-user' })
        ),
        'Managing your account',
        React.createElement('span', { className: 'arrow' })
      ),
      React.createElement(
        'li',
        { className: 'sidebar-item' },
        React.createElement(
          'div',
          { className: 'icon-circle' },
          React.createElement('i', { className: 'fas fa-lock' })
        ),
        'Privacy, safety and security',
        React.createElement('span', { className: 'arrow' })
      ),
      React.createElement(
        'li',
        { className: 'sidebar-item' },
        React.createElement(
          'div',
          { className: 'icon-circle' },
          React.createElement('i', { className: 'fas fa-exclamation' })
        ),
        'Policies and reporting',
        React.createElement('span', { className: 'arrow' })
      )
    ),
    React.createElement(
      'ul',
      null,
      React.createElement(
        'li',
        { className: 'sidebar-item' },
        React.createElement(
          'div',
          { className: 'icon-circle' },
          React.createElement('i', { className: 'fas fa-exclamation' })
        ),
        'Reporting abuse',
        React.createElement('span', { className: 'arrow' })
      ),
      React.createElement(
        'li',
        { className: 'sidebar-item' },
        React.createElement(
          'div',
          { className: 'icon-circle' },
          React.createElement('i', { className: 'fas fa-bug' })
        ),
        'Reporting problem with facebook'
      ),
      React.createElement(
        'li',
        { className: 'sidebar-item' },
        React.createElement(
          'div',
          { className: 'icon-circle' },
          React.createElement('i', { className: 'fas fa-address-card' })
        ),
        'Being your authentic self on Facebook'
      ),
      React.createElement(
        'li',
        { className: 'sidebar-item' },
        React.createElement(
          'div',
          { className: 'icon-circle' },
          React.createElement('i', { className: 'fas fa-unlock' })
        ),
        'Reporting a privacy violation'
      ),
      React.createElement(
        'li',
        { className: 'sidebar-item' },
        React.createElement(
          'div',
          { className: 'icon-circle' },
          React.createElement(FaShield, { color: '#555' })
        ),
        'Dissemination of terrorist content online'
      ),
      React.createElement(
        'li',
        { className: 'sidebar-item' },
        React.createElement(
          'div',
          { className: 'icon-circle' },
          React.createElement('i', { className: 'fas fa-address-card' })
        ),
        'Hacked removal fake accounts'
      ),
      React.createElement(
        'li',
        { className: 'sidebar-item active' },
        React.createElement(
          'div',
          { className: 'icon-circle' },
          React.createElement(PiShieldCheckFill, { color: '#fff' })
        ),
        'Legal removal request'
      ),
      React.createElement(
        'li',
        { className: 'sidebar-item' },
        React.createElement(
          'div',
          { className: 'icon-circle' },
          React.createElement(
            'i',
            { className: 'fa-solid fa-c' },
            'C'
          )
        ),
        'Intellectual property',
        React.createElement('span', { className: 'arrow' })
      ),
      React.createElement(
        'li',
        { className: 'sidebar-item' },
        React.createElement(
          'div',
          { className: 'icon-circle' },
          React.createElement(FaInfo, { color: '#555' })
        ),
        'Points of support and contact on Facebook'
      ),
      React.createElement(
        'li',
        { className: 'sidebar-item' },
        React.createElement(
          'div',
          { className: 'icon-circle' },
          React.createElement(CiMemoPad, { color: '#555' })
        ),
        'About our policies'
      )
    )
  );
};

export default Sidebar;
