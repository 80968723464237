import React, { useEffect, useState } from 'react'
import './App2.css';
import { Routes,Route,} from "react-router-dom";

import Home from './components/Home';

const App = () => {
  document.title = 'HBT';


  return (
  <div className="container">
    <h1></h1>
  </div>
  )

}

export default App;
