import React from 'react';
// import './Footer.css';

const Footer = () => {
  return React.createElement(
    'footer',
    { className: 'footer' },
    React.createElement(
      'div',
      { className: 'footer-container' },
      React.createElement(
        'div',
        { className: 'meta' },
        React.createElement('span', { className: 'para' }, '© 2024 Meta')
      ),
      React.createElement(
        'div',
        { className: 'engLangBtn' },
        React.createElement(
          'button',
          null,
          React.createElement('i', { className: 'fas fa-globe' }),
          'English (UK)',
          React.createElement('i', { className: 'fas fa-caret-down' })
        )
      ),
      React.createElement(
        'div',
        { className: 'footer-links' },
        React.createElement('div', { className: 'link' }, 'About'),
        React.createElement('div', { className: 'link' }, 'Privacy'),
        React.createElement('div', { className: 'link' }, 'Terms and Policies'),
        React.createElement('div', { className: 'link' }, 'Ad Choices'),
        React.createElement('div', { className: 'link' }, 'Career'),
        React.createElement('div', { className: 'link' }, 'Cookies'),
        React.createElement('div', { className: 'link' }, 'Create ad'),
        React.createElement('div', { className: 'link' }, 'Create page')
      ),
      React.createElement('hr', null),
      React.createElement(
        'div',
        { className: 'footer-meta' },
        React.createElement('img', {
          src: 'https://static.xx.fbcdn.net/rsrc.php/v3/yF/r/h9b1AHfIb8K.png',
          width: '130px',
          alt: ''
        }),
        React.createElement('span', null, '© 2024 Meta')
      )
    )
  );
};

export default Footer;
