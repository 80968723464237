export const mobileLanguages = [
  "پښتو",
  "العربية",
  "हिन्दी",
  "বাংলা",
  "ਪੰਜਾਬੀ",
  "Español",
];

export const desktoplanguages = [
  "English (US)",
  "François (Canada)",
  "پښتو",
  "العربية",
  "हिन्दी",
  "বাংলা",
  "ਪੰਜਾਬੀ",
  "فارسی",
  "ગુજરાતી",
  "Deutsch",
  "Español",
  "Italiano",
  "Deutsch",
];

export const footerLinks = [
  "Sign Up",
  "Log in",
  "Messenger",
  "Facebook Lite",
  "Video",
  "Places",
  "Games",
  "Marketplace",
  "Meta Pay",
  "Meta Store",
  "Meta Quest",
  "Meta AI",
  "Instagram",
  "Threads",
  "Fundraisers",
  "Services",
  "Voting Information Centre",
  "Privacy Policy",
  "Privacy Centre",
  "Groups",
  "About",
  "Create ad",
  "Create Page",
  "Developers",
  "Careers",
  "Cookies",
  "AdChoices 🚩",
  "Terms",
  "Help",
  "Contact uploading and non-users",
  "Settings",
];
